var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"主题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"人数"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'number',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'number',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"时间"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'time',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'time',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"参与人员"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'participant',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'participant',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"内容","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'content',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]),expression:"[\n              'content',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入！',\n                  },\n                ],\n              },\n            ]"}],attrs:{"auto-size":{
              minRows: 3,
            }}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }